@import './vars';

$family-sans-serif: "Helvetica Neue", "Helvetica", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Arial", sans-serif;

strong {
  color: inherit;
}

svg, img.is-icon {
  fill: $blue-700;
  position: relative;
  &.is-white {
    fill: white;
  }
  &.is-danger {
    fill: $red-400;
  }
  &.is-16 {
    height: 16px;
    width: 16px;
  }
  &.is-24 {
    height: 24px;
    width: 24px;
  }
  &.top-2px {
    top: 2px;
  }
  &.top-4px {
    top: 4px;
  }
  &.top-6px {
    top: 6px;
  }
  &.is-success {
    fill: $green-400;
  }
  &.is-info {
    fill: $blue-400;
  }
  &.is-info-dark {
    fill: $blue-500;
  }
}

.has-margin-left {
  margin-left: 8px;
}
.has-margin-right {
  margin-right: 8px;
}
.has-margin-left-4px {
  margin-left: 4px;
}
.has-margin-right-4px {
  margin-right: 4px;
}

.has-opacity-85 {
  opacity: 0.85;
}
.has-opacity-65 {
  opacity: 0.65;
}
.has-opacity-45 {
  opacity: 0.45;
}

.is-underlined {
  text-decoration: underline;
}


// Svg and text color styles
.is-green {
  color: $green-400;
  svg {
    fill: $green-400;
  }
  span {
    background: $green-400;
  }
  &:hover {
    color: $green-400;
  }
}
.is-purple {
  color: $purple-400;
  svg {
    fill: $purple-400;
  }
  span {
    background: $purple-400;
  }
  &:hover {
    color: $purple-400;
  }
}
.is-blue {
  color: $blue-500;
  svg {
    fill: $blue-500;
  }
  span {
    background: $blue-500;
  }
  &:hover {
    color: $blue-500;
  }
}
.is-red {
  color: $red-400;
  svg {
    fill: $red-400;
  }
  span {
    background: $red-400;
  }
  &:hover {
    color: $red-400;
  }
}

.is-yellow {
  color: $yellow-400;
  svg {
    fill: $yellow-400;
  }
  span {
    background: $yellow-400;
  }
  &:hover {
    color: $yellow-400;
  }
}
