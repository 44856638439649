@import './vars';
.action-bar {
  padding-top: 8px;
  padding-bottom: 8px;
  background: #fff;
  border-bottom: 1px solid rgba($text, 0.2);
  position: sticky;
  top: 0;
  z-index: 20;
  .level-left {
    flex-grow: 1;
  }
  .input, .field {
    border: none !important;
    height: 100% !important;
    width: 100%;
  }
  .button.is-static {
    border: none;
    background: none;
    height: 100% !important;
  }
  .back-button {
    cursor: pointer;
  }
}
