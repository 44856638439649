@import '../../stylesheets/vars';

.marker {
  position: relative;
  width: 18px;
  height: 18px;
  background-color: rgba($text, 0.8);
  border: 2px solid #fff;
  border-radius: 100%;
  user-select: none;
  transform: translate(-50%, -50%);
  // z-index: -1;
  &:hover,
  &.is-active {
    z-index: 1;
  }
  .marker-info {
    position: absolute;
    background: white;
    padding: 8px;
    top: 8px;
    left: 8px;
    // width: 120px;
    // z-index: 2;
    display: none;
    &.is-active {
      display: block;
    }
  }
  &.is-dangerous {
    background-color: rgba($red-400, 0.8);
  }

  &.is-unlicensed {
    background-color: rgba($yellow-500, 0.8);
  }
}
