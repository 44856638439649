@import '../../stylesheets/vars';

.menu-list {
  margin-top: 24px;
  li a {
    padding-left: 40px;
    // margin: 8px 0;
    color: $grey-600;
  }
}

.menu-subtitle {
  display: flex;
  align-items: center;
  margin: 8px 0;
  padding-left: 16px;
  img {
    height: 16px;
    margin-right: 8px;
  }
}

.menu-list li {
  img {
    height: 16px;
    margin-right: 8px;
  }
}

nav.navbar {
  border-bottom: 1px solid $grey-400;
}
