@import './vars';

.title-banner {
  .card-content {
    position: relative;
    display: inline-block;
    img {
      position: absolute;
      &:first-of-type {
        left: -120px;
        top: 40px;
      }
      &:last-of-type {
        right: -120px;
        top: 0px;
      }
    }
  }
}
