@import "./vars";

.table.is-striped tbody tr:not(.is-selected):nth-child(even) {
  background: rgba($text, 0.03);
}
.table.is-hoverable.is-striped tbody tr:not(.is-selected):hover:nth-child(even),
.table.is-hoverable.is-striped tbody tr:not(.is-selected):hover:nth-child(odd) {
  background: rgba($text, 0.1);
}

.table-container {
  border-radius: 4px;
}
.table {
  border: 1px solid rgba($text, .24);
  td, th {
    padding: 1em 1.75em;
    border: none;
  }
  thead {
    background: $text !important;
    th {
      font-weight: normal;
      padding-top: 16px;
      padding-bottom: 16px;
    }
  }
}

// tr.is-selected {
//   background: rgba($text, 0.03);
// }
