// Colors

$teal-700: #124544;
$teal-600: #1B655E;
$teal-500: #2A9187;
$teal-400: #3BADA2;
$teal-300: #6ED7D3;
$teal-200: #A8EEEB;
$teal-100: #E7FFFE;

$green-700: #3B5214;
$green-600: #557819;
$green-500: #729E26;
$green-400: #8FC238;
$green-300: #B3D973;
$green-200: #D4EDA8;
$green-100: #F3FCE3;

$yellow-700: #5C4013;
$yellow-600: #8C621F;
$yellow-500: #C9933C;
$yellow-400: #EBA83C;
$yellow-300: #FAD7A0;
$yellow-200: #FCEED7;
$yellow-100: #FFFBF5;

$red-700: #611818;
$red-600: #891B1B;
$red-500: #B82020;
$red-400: #e96463;
$red-300: #F08B8B;
$red-200: #F5AAAA;
$red-100: #FCE8E8;

$blue-700: #204254;
$blue-600: #1A5270;
$blue-500: #2477A3;
$blue-400: #45B6F2;
$blue-300: #64B0D9;
$blue-200: #A9DAF5;
$blue-100: #F0FAFF;


$grey-900: #252529;
$grey-800: #3B3D40;
$grey-700: #505257;
$grey-600: #8A9096;
$grey-500: #B2B7BF;
$grey-400: #D0D4DB;
$grey-300: #D7DBE0;
$grey-200: #E4E6EB;
$grey-100: #EDF0F5;
$grey-50: #F4F7FC;

$purple-700: #181F40;
$purple-600: #1F2C6E;
$purple-500: #3447A3;
$purple-400: #4A5EC7;
$purple-300: #7587E0;
$purple-200: #D2D8F7;
$purple-100: #F0F2FF;


// Mixins: ;
@mixin shadow {
  box-shadow: 0px 5px 14px 0 rgba(#000000, 0.1);
}

@mixin border {
  border: 1px solid $grey-200 !important;
}

// Set your brand colors
$richmond: #b4349b;

// Update Bulma's global variables
$text: #122435;
$dark: $text;
$grey-dark: $grey-700;
$grey-light: $grey-100;
$primary: $text;
$link: $blue-500;



// Update some of Bulma's component variables
$body-background-color: rgba($grey-50, 0.4);
$control-border-width: 1px;
$input-border-color: transparent;
$input-shadow: none;
$dropdown-content-shadow: none;
$progress-value-background-color: hsl(0, 0%, 70%);


//card
// $card-header-background-color: $text;
$card-header-color: #fff;
// $card-header-padding: 0.75rem 1.5rem;
$card-shadow: none;
