@charset "utf-8";

// Import a Google Font
// @import "../fonts/_fonts"

// Set your brand colors
$primary: #1C2A48;
$bg-color: #F8F8F9;
$richmond: #be1fa0;
$danger: red;

.has-background-white-bis {
  background: $bg-color; }

label.label {
  text-transform: uppercase;
  font-size: .75rem;
  font-weight: normal;
 }  // opacity: 0.4
.input, .select select {
  border: 1px solid rgba($primary, 0.24) !important;
  border-color: rgba($primary, 0.24) !important;
  box-shadow: none !important;
  &:hover {
    border-color: rgba($primary, 0.4) !important; } }
.is-danger.input, .is-danger.textarea {
  border-color: #ff3860 !important; }

.file-cta {
  background: white;
  background-position: center;
  background-size: cover;
  border: 1px dashed rgba($primary, 0.24) !important;
  min-height: 160px;
  justify-content: center;
  &.is-danger {
    border: 1px dashed $danger !important; } }

.checkbox {
  input[type="checkbox"] {
    position: relative;
    margin-top: 3px;
    margin-right: 4px;
    vertical-align: top;
    border: none;
    outline: none;
    background-color: transparent;
    -webkit-appearance: none;
    appearance: none;
    cursor: pointer;
    &::before,
    &::after {
      content: "";
      display: block;
      width: 18px;
      height: 18px;
      -webkit-transition: 240ms;
      -o-transition: 240ms;
      transition: 240ms; }
    &::after {
      width: 18px;
      height: 18px;
      border-radius: 2px;
      -webkit-transition: 240ms;
      -o-transition: 240ms;
      transition: 240ms;
      position: relative;
      top: -3px;
      border: 1px solid $primary; }
    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 6px;
      display: table;
      width: 5px;
      height: 10px;
      border-top-width: 0;
      border-left-width: 0;
      transform: rotate(45deg) scale(0);
      transform-origin: center;
      transition: all .25s ease; }
    &:checked {
      &::after {
        border-color: $primary; }
      &::before {
        border: 2px solid $primary;
        border-top-width: 0;
        border-left-width: 0;
        transform: rotate(45deg) scale(1); } } } }

.checkbox.is-white {
  input[type="checkbox"] {
    &::after {
      border: 1px solid white; }
    &:checked {
      &::after {
        border-color: white; }
      &::before {
        border-color: white; } } } }

.has-opacity-85 {
  opacity: 0.85; }

.has-opacity-65 {
  opacity: 0.65; }

.has-opacity-45 {
  opacity: 0.45; }

.is-aligned-bottom {
  align-items: flex-end; }
.is-flex-column {
  flex-direction: column; }
.is-justified-center {
  justify-content: center; }

.broward-header {
  padding-top: 24px;
  padding-bottom: 24px;
  background: {
    // image: url('http://www.broward.org/Style%20Library/V7/img/HomeHero/hero9.jpg')
    size: cover;
    position: center 70%;
    repeat: no-repeat; }
  position: relative;
  &::after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba($primary, 0.44); }
  &.arl {
    background: {
 }      // image: url('../img/arlington.jpg')
    &::after {
      background: rgba(#247dd6, 0.84); } }
  &.richmond {
    background: {
 }      // image: url('../img/richmond-bg.jpg')
    &::after {
      background: rgba($richmond, 0.82); } }
  .container {
    position: relative;
    z-index: 2; } }

.broward-logo {
  img {
    height: 80px; } }

.license-row {
  &:hover {
    background: rgba($primary, 0.05); } }

.progress-tabs {
  margin-bottom: 32px !important;
  & > div {
    opacity: 0.32;
    // cursor: pointer
    &.active {
      opacity: 1; } }
  & > div  > span {
    background: $primary;
    height: 4px;
    width: 100%;
    display: block;
    border-radius: 4px; } }

.tab-content {
  display: none !important;
  &.active {
    display: flex !important; } }

.radio-image {
  img {
    height: auto; }
  input {
    display: none;
    opacity: 1;
    & + div {
      opacity: 1;
      transition: all .25s ease; }
    &:not(:checked) + div {
      opacity: 0.4;
      filter: grayscale(1); } } }

.price {
  justify-content: space-between;
  padding: 0.75rem; }

// .card
//   border-radius: 4px
//   box-shadow: none
//   border: 1px solid rgba($primary, 0.24) !important
//   &.is-danger
//     border: 1px solid $danger !important

.digital-certification {
  &.inactive {
    opacity: 0.4;
    pointer-events: none; } }
.mailing-address {
  display: none;
  &.active {
    display: block; } }



.terms-card {
  .card-header {
    padding: 2.25rem;
    position: relative;
    box-shadow: none !important;
    p {
      flex-grow: 2;
      position: relative;
      z-index: 3; }
    img {
      position: absolute;
      height: auto;
      width: 140px;
      bottom: 0;
      &:last-of-type {
        right: 80px; }
      &:first-of-type {
        left: 80px; } } }
  .card-content {
    // max-height: 400px
    overflow: scroll; }
  @media (max-width: 768px) {
    .card-header {
      img {
        &:last-of-type {
          right: 24px; }
        &:first-of-type {
          left: 24px; } } } } }


.broward-sort {
  .card {
    height: 100%;
    .card-content {
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center; } } }

.tag-status {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  display: inline-block;
  background: blue;
  margin-right: 4px;
  &.tag-active {
    background: #1CB8A4; }
  &.tag-inactive {
    background: #EF8484; } }

.StripeElement {
  // height: 40px
  padding: 8px 4px;

  color: #32325d;
  background-color: white;
  border: 1px solid rgba(28,42,72,0.24) !important;
  border-radius: 4px; }

.StripeElement--focus {
  border: 1px solid rgba(28,42,72,0.64) !important; }
.StripeElement--invalid {
  border-color: #fa755a; }

.StripeElement--webkit-autofill {
  background-color: #fefde5 !important; }
.ElementsApp, .ElementsApp .InputElement {
  height: auto; }

// .richmond-style
//   .progress-tabs>div
//     color: #be1fa0
//   .progress-tabs>div>span
//     background: #be1fa0 !important
